
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Bio"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"bios_bio_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uid"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"title"},"name":{"kind":"Name","value":"customTitle"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"position"},"name":{"kind":"Name","value":"headline"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"content"},"name":{"kind":"Name","value":"simpleText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"photo"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":164}};
    doc.loc.source = {"body":"fragment Bio on bios_bio_BlockType {\n  __typename\n  uid\n  name: description\n  title: customTitle\n  position: headline\n  content: simpleText\n  photo {\n    url\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
