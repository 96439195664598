import React from 'react';
import cc from 'classcat';
import { NextPageContext } from 'next';

import { PageUp } from '../../components';
import { useStore } from '../../hooks';
import NotFoundPage from '../404';

import Page from '../[...all]';

import styles from './index.module.scss';

interface Props {
  uri: string;
}

const Jobs = ({ uri }: Props) => {
  const { site } = useStore('globals');

  if (site !== 'careers') {
    return <NotFoundPage />;
  }

  return (
    <div>
      <Page uri={uri} />
      <div className={cc(['section', styles.jobs])}>
        <div className="container">
          <PageUp />
        </div>
      </div>
    </div>
  );
};

Jobs.getInitialProps = ({ req }: NextPageContext) => ({
  uri: req?.url?.replace(/^\//, '').replace(/\?.*$/, '') || '404',
});

export default Jobs;
