export function trackEvent(eventCategory: string, eventAction: string, eventLabel = '') {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined = window;
  if (gWindow && gWindow.dataLayer) {
    gWindow.dataLayer.push({
      event: 'GAEvent',
      eventCategory,
      eventAction,
      eventLabel,
    });
  }
}

export function trackViewItem(window: any, courseId: string, courseName: string) {
  window.dataLayer?.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_id: courseId,
          item_name: courseName,
        }
      ]
    }
  });
}

export function trackAddToCart(window: any, courseId: string, courseName: string) {
    window.dataLayer?.push({
        event: 'add_to_cart',
        ecommerce: {
            items: [
                {
                    item_id: courseId,
                    item_name: courseName,
                }
            ]
        }
    });
}

export function trackPurchase(window: any, items: object[], bookingNumber: string|string[]|null, totalPaid: string) {
    window.dataLayer?.push({
        event: 'purchase',
        ecommerce: {
            transaction_id: bookingNumber,
            value: parseFloat(totalPaid),
            currency: "AUD",
            items: items
        }
    });
}
