import * as React from 'react';

const NotFoundPage = () => (
  <div className="errorContainer">
    <h1>404</h1>
    <h2 style={{ textAlign: 'center' }}>Page Not Found</h2>
    <br />
  </div>
);

export default NotFoundPage;
