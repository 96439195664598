import React from 'react';
import { useEffectOnce } from 'react-use';
import { NextPageContext } from 'next';
import scrollIntoView from 'scroll-into-view';

import {
  ActivitiesGlobals,
  Bio,
  BreadcrumbsPage,
  Hero,
  PagesInSection,
  SearchBlock,
  ServicesGlobals,
} from '../../components';
import { useIsMobile } from '../../hooks';
import { useSiteQuery } from '../../hooks';
import { getBioHeadings, getHeadings, renderPageContent, renderRowContent } from '../../lib/ContentRenderer';
import ErrorPage from '../_error';

import query from './page.graphql';
import {SitemapHelper} from "../../lib/SitemapHelper";

interface Props {
  uri: string;
  showBreadcrumbs?: boolean;
  children?: React.ReactNode;
  isSitemapRequest?: boolean;
}

const renderPageEntry = (
  entry: PageEntryContentFragment,
  homeCareCategories: Maybe<HomeCareCategoriesFragment>[] | null | undefined,
  showBreadcrumbs = false,
  children: React.ReactNode = null,
) => {
  const headings = getHeadings(entry?.pageContent, homeCareCategories);

  const showServiceGlobals = ['retirement-villages', 'nursing-homes'].includes(entry.uri!);
  const showSearchBlock = ['retirement-villages/locations', 'nursing-homes/locations'].includes(entry.uri!);
  return (
    <div>
      {(entry.level! > 1 || showBreadcrumbs) && (
        <BreadcrumbsPage pageId={parseInt(entry.id!)} title={entry.title!} uri={entry.uri!} />
      )}
      {entry.hero?.[0] && (
        <Hero heroArea={entry.hero[0]} parent={entry.parent?.title || entry.title!} headings={headings}>
          {showSearchBlock && <SearchBlock />}
        </Hero>
      )}
      {entry.toggle && <PagesInSection uri={entry.uri!} />}
      {!(showServiceGlobals || showSearchBlock) && entry.pageContent?.map((row) => renderPageContent(row!, false, entry))}
      {(showServiceGlobals || showSearchBlock) && entry.pageContent?.map((row) => renderRowContent(row!))}
      {entry.uri === 'retirement-villages' && <ActivitiesGlobals />}
      {showServiceGlobals && (
        <ServicesGlobals isMain={entry.uri === 'nursing-homes'} isNursingHome={entry.uri === 'nursing-homes'} />
      )}
      {children}
      {(showServiceGlobals || showSearchBlock) && entry.pageContent?.map((row) => renderPageContent(row!, true, entry))}
    </div>
  );
};

const renderPathwaysEntry = (entry: PageEntryContentFragment, children: React.ReactNode = null) => {
  const headings = getHeadings(entry?.pageContent, []);

  return (
    <div>
      {entry.level! > 1 && <BreadcrumbsPage pageId={parseInt(entry.id!)} title={entry.title!} uri={entry.uri!} />}
      {entry.hero?.[0] && (
        <Hero heroArea={entry.hero[0]} parent={entry.parent?.title || entry.title!} headings={headings} />
      )}
      {entry.toggle && <PagesInSection uri={entry.uri!} />}
      {entry.pageContent?.slice(0, 1).map((row) => renderPageContent(row!, false, entry))}
      {children}
      {entry.pageContent?.slice(1).map((row) => renderPageContent(row!, false, entry))}
    </div>
  );
};

const renderBioEntry = (entry: BioEntryContentFragment) => {
  const headings = getBioHeadings(entry?.bios);
  return (
    <div>
      <BreadcrumbsPage pageId={parseInt(entry.id!)} title={entry.title!} uri={entry.uri!} />
      {entry.hero?.[0] && (
        <Hero heroArea={entry.hero[0]} parent={entry.parent?.title || entry.title!} headings={headings} />
      )}
      {entry.toggle && <PagesInSection uri={entry.uri!} />}
      {entry.bios && (
        <div className="section">
          <div className="container">
            {entry.bios?.map((row, index) => row && <Bio key={row.uid} item={row} index={index} />)}
          </div>
        </div>
      )}
    </div>
  );
};

const Page = ({ isSitemapRequest, uri, showBreadcrumbs, children }: Props) => {
  if (isSitemapRequest) {
    return null;
  }

  const [{ data, error }] = useSiteQuery<PageQuery>({ query, variables: { uri } });
  const [isMobile] = useIsMobile(768);

  useEffectOnce(() => {
    if (process.browser && uri === 'home-care/our-home-care-services') {
      const hash = document.getElementById(window.location.hash.substring(1));
      if (hash) {
        scrollIntoView(hash, { align: { top: 0, topOffset: !isMobile ? 400 : 250 } });
      }
    }
  });

  if (error) {
    return <ErrorPage statusCode={500} err={error} />;
  }

  const entry = data?.entry;
  if (entry) {
    switch (entry.__typename) {
      case 'pages_pages_Entry':
        if (uri === 'career-pathways') {
          return renderPathwaysEntry(entry, children);
        }
        return renderPageEntry(entry, data?.homeCareCategories, showBreadcrumbs, children);
      case 'pages_bio_Entry':
        return renderBioEntry(entry);
      default:
        return (
          <div>
            {(entry.level! > 1 || showBreadcrumbs) && (
              <BreadcrumbsPage pageId={parseInt(entry.id!)} title={entry.title!} uri={uri} />
            )}
          </div>
        );
    }
  }
  return <ErrorPage statusCode={404} err={error} />;
};

Page.getInitialProps = async ({ req,res }: NextPageContext) => {
  let isSitemapRequest = false;

  // Support sitemaps from headless craft backend
  if (req?.url?.startsWith('/sitemap')) {
    isSitemapRequest = true;
    let response = '';

    // We cant use react hooks (useQuery useStore etc) inside getInitialProps so lets utilise a helper to fetch our gql
    if (req?.url === '/sitemap.xml') {
      response = await SitemapHelper.getSitemapIndex();
    } else if (req?.url?.endsWith('.xml')) {
      response = await SitemapHelper.getSitemapFile(req?.url);
    } else if (req?.url?.endsWith('.xsl')) {
      response = await SitemapHelper.getSitemapStyle();
    }

    // Set content type to xml and write our raw response to the output stream
    res?.setHeader('Content-Type', 'text/xml');
    res?.write(response || '');
    res?.end();
  }

  return {
    uri: req?.url?.replace(/^\//, '').replace(/\?.*$/, '') || '404',
    isSitemapRequest
  }
};

export default Page;